.important-section {
    padding: 0px 0;
    background-image: url("../../../public/images/creatives/contact-banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    padding-top: 160px;
    background-size: cover;
    background-position: center;
    color: white;
    flex-wrap: wrap;
}

.contact-container input,
.contact-container textarea {
    border: none;
    border-bottom: 0.5px solid #a7a7a775;
    border-radius: 0%;
    resize: none;
    background-color: transparent;
    color: white;
}

.contact-container input::placeholder,
.contact-container textarea::placeholder {
    color: #FFFFFF;
    font-size: 12px;
}

.contact-info {
    max-width: 40%;
    flex: 1;
}

.contact-info h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 20px;
    font-family: 'manrope', sans-serif;
}

.contact-info p {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.8;
    color: #FFFFFF;
    font-family: 'manrope', sans-serif;
}

.contact-details {
    display: flex;
}

.contact-details p {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-family: 'manrope', sans-serif;
}

.contact-container .social-icons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.contact-form {
    color: white;
    padding: 78px 50px;
    border-radius: 21px;
    border: solid 1px #FFFFFF33;
    max-width: 45%;
    flex: 1;
    box-shadow: 0px -6px 40px -4px rgba(0, 0, 0, 0.080),
        0px 6px 40px -4px rgba(0, 0, 0, 0.080);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 14px;
}

.contact-form .h1_main {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: 600;
    color: #FFFFFF;
}

.contact-form p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #FFFFFF;
}

.contact-form .form-group {
    margin-bottom: 15px;
}

.contact-form .submit-button {
    background-color: #A5CD37;
    border: none;
    border-radius: 50px;
}

.contact-info .h1_main {
    font-size: 38px;
    color: #FFFFFF;
}

.contact-info .span-text {
    color: #8EC63F;
}

.contact-info .para_main {
    color: #FFFFFF;
}

.contact-container .informational-para {
    font-size: 15px;
}

.contact-container .anchor {
    margin: 0;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    color: white;
    font-size: 15px;
    margin-top: 8px;
    line-height: 3;
    text-decoration: none;
}

.contact-container .contact-content {
    display: flex;
}

@media (max-width: 1200px) {
    .contact-container {
        padding: 30px;
        height: 100vh;
    }

    .contact-info h1 {
        font-size: 2.5rem;
    }

    .contact-form .h1_main {
        font-size: 1.8rem;
    }
}

@media (max-width: 992px) {
    .contact-info {
        max-width: 45%;
    }

    /* .contact-form {
        max-width: 45%;
    } */

    .contact-info h1 {
        font-size: 2.2rem;
    }

    .contact-form .h1_main {
        font-size: 1.6rem;
    }

    .contact-form .contact-details {
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .important-section {
        background-size: cover;
        padding: 50px 16px;
    }

    .contact-container {
        flex-direction: column;
        height: auto;
        padding: 0px;
    }

    .contact-details {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;
        margin-bottom: 12px;
    }

    .contact-details img {
        max-width: 24px;
        height: auto;
    }

    .contact-details p {
        font-size: 0.9rem;
        margin: 0;
    }

    .contact-info {
        max-width: 100%;
        flex: none;
        margin-bottom: 20px;
    }

    .contact-form {
        max-width: 100%;
        padding: 50px 55px;
    }

    .contact-info h1 {
        font-size: 2rem;
    }

    .contact-form .h1_main {
        font-size: 1.5rem;
    }

    .contact-container .h1_main {
        text-align: center;
    }

    .contact-container .para_main {
        text-align: center;
    }
}

@media (max-width: 576px) {
    .important-section {
        background-size: cover;
        padding: 50px 16px;
    }

    .contact-info h1 {
        font-size: 1.8rem;
    }

    .contact-form .h1_main {
        font-size: 1.4rem;
    }

    .contact-details {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;
        margin-bottom: 12px;

    }

    .contact-details img {
        max-width: 24px;
        height: auto;
    }

    .contact-details p {
        font-size: 0.9rem;
        margin: 0;
    }

    .contact-form p {
        font-size: 0.9rem;
    }

    .contact-info {
        padding: 3vh 0vh;
    }

    .contact-info p {
        font-size: 0.9rem;
    }

    .contact-container .social-icons {
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
    }

    .contact-container .h1_main {
        text-align: center;
    }

    .contact-container .para_main {
        text-align: center;
    }
}